import React, { useState } from 'react'
import ReactDOM from 'react-dom'
/** @jsx jsx */
import { jsx, css, keyframes } from '@emotion/core'
import { useSpring, animated } from 'react-spring';
import { theme, headerHeight, mobiHeaderHeight } from '../common/site-variables';
import { ButtonBase, Badge, Divider, List, ListItem, ListItemAvatar, ListItemText, Button, Avatar, ListItemSecondaryAction, IconButton, Grid } from '@material-ui/core';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'
import CloseIcon from '@material-ui/icons/Close'
import RemoveIcon from '@material-ui/icons/Remove'
import AddIcon from '@material-ui/icons/Add'
import CartContext from '../data/cart-context';
import { flexColCenterAll, flexRowCenterAll } from '../common/format-styles';
import { Product } from '../data/product-model';
import { toCurrency } from '../helper/to-currency';
import { PayPalButton } from "react-paypal-button-v2";
import { navigate } from 'gatsby';
import styled from '@emotion/styled';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import {
  breakpoints,
  colors,
  fonts,
  spacing,
  dimensions
} from '../utils/styles';
import CartList from './CartList';
import EmptyCart from './EmptyCart';
import { getCurrentUser, getCurrentUserId, signIn, signUp } from '../services/auth-service';

declare const paypal;

interface PropTypes {
    isMobile: boolean;
}

const FloatingCart = (props: PropTypes) => {
    const { isMobile } = props;

    const CartRoot = styled(`div`)`
        background: none;
        position: relative;
        right: 0;
        top: 5px;
        width: 100%;
        z-index: 1000;
        padding: 0 5%;
        color: ${colors.lightest};

        ::after {
            background-color: ${colors.lightest};
            bottom: 0;
            content: '';
            left: 0;
            opacity: 0;
            pointer-events: none;
            position: absolute;
            right: 0;
            top: 0;
            transition: all 250ms;
        }
 
        
    `;

    const Heading = styled(`header`)`
        align-items: center;
        display: flex;
        height: ${isMobile ? mobiHeaderHeight : headerHeight };
        justify-content: flex-start;
        margin-top: ${isMobile ? '5%' : 0};
    `;

    const Title = styled(`h2`)`
        flex-grow: 1;
        font-family: ${fonts.heading};
        font-size: 1.8rem;
        margin: 0;
        margin-left: ${spacing.md}px;
        position: relative;

        .open & {
            margin-left: calc(${isMobile ? mobiHeaderHeight : headerHeight } + ${spacing.md}px);

            @media (min-width: ${breakpoints.desktop}px) {
            margin-left: ${spacing.md}px;
            }
        }
    `;

    const Content = styled(`div`)`
        bottom: 0;
        overflow-y: auto;
        padding: ${spacing.lg}px;
        position: relative;
        top: ${isMobile ? mobiHeaderHeight : headerHeight };
        width: 100%;

        @media (min-width: ${breakpoints.desktop}px) {
            ::-webkit-scrollbar {
            height: 6px;
            width: 6px;
            }
            ::-webkit-scrollbar-thumb {
            background: ${colors.brandBright};
            }
            ::-webkit-scrollbar-thumb:hover {
            background: ${colors.lilac};
            }
            ::-webkit-scrollbar-track {
            background: ${colors.brandLight};
            }
        }
    `;

    const ItemsNumber = styled(`span`)`
        align-items: center;
        background: ${theme.cs_yellow};
        border-radius: 50%;
        color: ${theme.cs_blue};
        display: flex;
        font-size: 1.3rem;
        font-weight: bold;
        height: 36px;
        justify-content: center;
        width: 36px;
    `;

    const ItemsInCart = styled(`div`)`
        align-items: center;
        display: flex;
        font-size: 0.8rem;
        line-height: 1.2;
        text-align: right;

        ${ItemsNumber} {
            margin-left: ${spacing.xs}px;
            margin-right: ${spacing.md}px;
        }
    `;

    const Costs = styled('div')`
        display: flex;
        flex-direction: column;
        margin-top: ${spacing.sm}px;
    `;

    const Cost = styled(`div`)`
        display: flex;
        padding: 0 ${spacing.xs}px ${spacing['2xs']}px;

        :last-child {
            padding-bottom: 0;
        }

        span {
            color: ${theme.cs_lighter_blue};
            flex-basis: 60%;
            font-size: 0.9rem;
            text-align: right;
        }

        strong {
            color: ${theme.cs_lighter_blue};
            flex-basis: 40%;
            text-align: right;
        }
    `;

    const Total = styled(Cost)`
        border-top: 1px solid ${colors.brandBright};
        color: ${theme.cs_yellow};
        margin-top: ${spacing.xs}px;
        padding-top: ${spacing.sm}px;
        margin-bottom: ${spacing.xl}px;

        span {
            font-weight: bold;
            text-transform: uppercase;
        }

        strong,
        span {
            color: inherit;
        }
    `;

    const iconEntry = keyframes`
        0%, 50% {
            transform: scale(0)
        }
        90% {
            transform: scale(1.2);
        }
        100% {
            transform: scale(1);
        }
    `;

    const numberEntry = keyframes`
        0%{
            transform: scale(0)
        }
        90% {
            transform: scale(0.7);
        }
        100% {
            transform: scale(0.6);
        }
    `;


    const CheckOut = styled(`button`)`
        font-size: 1.25rem;
        margin: ${spacing.lg}px 0 ${spacing.md}px;
        width: 100%;
    `;

    const BackLink = styled(Button)`
        font-size: 1.25rem;
        margin-bottom: ${spacing.sm}px;
        width: 100%;
        color: ${theme.cs_lighter_blue};
    `;


    const cartState = React.useContext(CartContext);
    const [cartTotal, setTotal] = React.useState({numOfProd: 0, subTotal: 0});



    React.useEffect(() => {
        let isMounted = true;
        // signIn('swizzlej@gmail.com', 'Jasemnat_2016@Directus').then(
        //     res => {
        //        console.log(res);
               
        //     }, errors => console.log(errors)
        // );
        // getCurrentUserId().then(
        //     res => {
        //         console.log(res);
                
        //     }, errors => console.log(errors)
        // )
        
        if (isMounted) {
            let numOfProd = 0;
            let subTotal = 0;
            cartState.cartProducts.forEach(product => {
                numOfProd += product.quantity;
                subTotal += product.price * product.quantity;
            });
            setTotal({numOfProd: numOfProd, subTotal: subTotal});
        }

        //const PayPalButton = paypal.Button.driver('react', { React, ReactDOM });

        // paypal.Buttons({
        //     createOrder: (data, actions) => {
        //         // This function sets up the details of the transaction, including the amount and line item details.
        //         return actions.order.create({
        //           purchase_units: [{
        //             amount: {
        //               value: '10'
        //             }
        //           }]
        //         });
        //     },

        //     onApprove: function(data, actions) {
        //         // This function captures the funds from the transaction.
        //         return actions.order.capture().then(function(details) {
        //           // This function shows a transaction success message to your buyer.
        //           alert('Transaction completed by ' + details.payer.name.given_name);
        //         });
        //     }
        // }).render('#paypal-button-container');

        return () => { isMounted = false };

    }, [cartState]);

    const [cartOpen, setCartOpen] = React.useState(false);

    const [cartBtnSpring, setCartBtnSpring] = useSpring(() => ({
        right: '0vw',
        config: {
            velocity: 5
        }
    }));
    const [cartSpring, setCartSpring] = useSpring(() => ({
        right: isMobile ? '-100vw' : '-30vw',
        config: {
            velocity: 5
        }
    }));

    const styles = css(`
        position: absolute;
        top: 0; 
        right: 0;

        .cartButton {
            position: fixed;
            top: ${isMobile ? mobiHeaderHeight : headerHeight };
            z-index: 1000;
            width: ${isMobile ? 12 : 4 }vw;
            height: ${isMobile ? 12 : 4 }vw;
            background-color: ${theme.cs_dark_blue};
            color: white;

        }
        .cart {
            position: fixed;
            top: ${isMobile ? mobiHeaderHeight : headerHeight };
            right: 0;
            z-index: 999;
            width: ${isMobile ? 100 : 30 }vw;
            height: calc(100vh - ${headerHeight});
            background-color: ${theme.cs_dark_blue};
            overflow-y: auto;

            .header {
                color: #ececec;
                box-sizing: border-box;
                text-align: center;
                padding: 45px 0;

                .headerTitle {
                    font-weight: 700;
                    font-size: 1.2em;
                    vertical-align: middle;
                    margin-left: 5%;
                }
            }

            .content {
                padding-bottom: 200px;
                position: relative;
                min-height: 280px;

                .listItem {
                    padding: 5%;  
                    
                }
                
                .actionDiv {
                    height: 100%;
                    position: relative;

                   .removeBtn {
                        position: absolute;
                        top: 0;
                        right: 0;
                        
                    }
                }

                .title {
                    font-size: 1em;
                    color: white;
                }

                .prodDesc {
                    font-size: .8em;
                }


                .prodQuantity, .prodDesc {
                    display: block;
                    color: ${theme.cs_lighter_blue};
                    width: 70%;
                }

                .highlight {
                    color: ${theme.cs_yellow};
                }
            }

            .footer {
                box-sizing: border-box;
                padding: 1.5% 3% 1% 3%;
                position: fixed;
                bottom: 0;
                width: ${isMobile ? 100 : 30 }vw;
                z-index: 2;
                background-color: ${theme.cs_dark_blue};
                max-height: calc(100vh - ${headerHeight});
                overflow-y: auto;

                &:before {
                    content: "";
                    width: 100%;
                    height: 20px;
                    display: block;
                    position: absolute;
                    top: -20px;
                    left: 0;
                    background: -webkit-linear-gradient(bottom,rgba(0,0,0,.2),transparent);
                    background: linear-gradient(0deg,rgba(0,0,0,.2),transparent);
                }

                .total {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    color: ${theme.cs_lighter_blue};

                    .highlight {
                        color: ${theme.cs_yellow};
                    }
                }

                button {
                    width: 100%;
                    color: ${theme.cs_lighter_blue};
                    background-color: ${theme.cs_darker_blue};
                    margin: 5% 0;
                }
            }
        }

        .MuiBadge-badge {
            background-color: ${theme.cs_yellow};
            color: ${theme.cs_dark_blue};
        }

        #card-fields-container {
            zoid-paypal-card-fields-4b77d73401_mte6mtg6mtg {
                color: ${theme.cs_yellow};
            }
            
        }

    `);

    const AnimatedButtonBase = animated(ButtonBase);

    const handleCartOpen = () => {
        setCartBtnSpring({right: cartOpen ? '0vw' : `${isMobile ? 88 : 30}vw`});
        setCartSpring({right: cartOpen ? `${isMobile ? -100 : -30}vw` : '0'});
        setCartOpen(!cartOpen);
    }

    

    return (
        <div css={styles} >
            <AnimatedButtonBase style={cartBtnSpring} className="cartButton" onClick={handleCartOpen}>
                {
                    cartOpen ? 
                    <CloseIcon />
                    :
                    <Badge 
                        badgeContent={cartTotal.numOfProd} 
                        anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                    >
                        <ShoppingCartIcon />
                    </Badge>
                }
            </AnimatedButtonBase>

            <animated.div style={cartSpring} className="cart">
                <CartRoot>
                    <Heading>
                        <Title>Your Cart</Title>
                        <ItemsInCart>
                            items
                            <br />
                            in cart
                            <ItemsNumber>{cartTotal.numOfProd}</ItemsNumber>
                        </ItemsInCart>
                    </Heading>

                    {true ? (
                    <Content>
                        <CartList
                            cartState={cartState}
                        />

                        <Costs>
                            <Cost>
                            <span>Subtotal:</span>{' '}
                            <strong>USD {toCurrency(cartTotal.subTotal)}</strong>
                            </Cost>
                            <Cost>
                                <span>Shipping (worldwide):</span> <strong>FREE</strong>
                            </Cost>
                            <Total>
                                <span>Total Price:</span>
                                <strong>USD {toCurrency(cartTotal.subTotal)}</strong>
                            </Total>
                        </Costs>

                        <div css={css(`display: ${cartTotal.subTotal ? 'none' : 'initial'}`)}>
                            {/* <PayPalButton
                                amount={cartTotal.subTotal}
                                onButtonReady={() => {

                                }}
                                // shippingPreference="NO_SHIPPING" // default is "GET_FROM_FILE"
                                onSuccess={(details, data) => {
                                alert("Transaction completed by " + details.payer.name.given_name);
                        
                                // OPTIONAL: Call your server to save the transaction
                                // return fetch("/paypal-transaction-complete", {
                                //     method: "post",
                                //     body: JSON.stringify({
                                //     orderID: data.orderID
                                //     })
                                // });
                                // }
                                }}
                            /> */}
                        </div>

                        {/* <BackLink onClick={createUser}>create user</BackLink> */}
                        <BackLink onClick={handleCartOpen}>
                            <ArrowBackIcon />
                            Back to shopping
                        </BackLink>

                        {/* <ShippingInfo /> */}
                    </Content>
                    ) : (
                        <EmptyCart />
                    )}
                </CartRoot>
            </animated.div>
        </div>
    )
}

export default FloatingCart